import './App.css';

function App() {
  return (
    <div className="App">
 <div className="banner">
 <img src="../giphy.webp" />
 </div>
    </div>
  );
}

export default App;
